import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

import Intercom from 'react-intercom';

import { getToken, isAuthenticated, login, logout, removeCookie } from '../services/auth';
import api from '../services/api';
import authApi from '../services/authApi';
import Loading from '../components/atoms/Loading';
import { getUrlParam } from '../utils/params';

const Signin = lazy(() => import('../pages/SignIn'));
const Verify = lazy(() => import('../pages/Verify'));
const Signup = lazy(() => import('../pages/Signup'));
const AccountSettings = lazy(() => import('../pages/AccountSettings'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const MailStatus = lazy(() => import('../pages/MailStatus'));
const ConfirmEmail = lazy(() => import('../pages/ConfirmEmail'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ConfirmedEmail = lazy(() => import('../pages/ConfirmedEmail'));

const SettingsRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(rest.location.search);

  if (queryParams.get('access_token')) {
    login(queryParams.get('access_token'));
  }

  api.get('/me', {}).catch(() => {
    logout();
    history.push('/accounts/sign_in');
  });

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  authApi
    .get('/me')
    .then((meResponse) => {
      const authentication = meResponse.data;
      if (authentication.status === 'confirmation_expired') {
        logout();
      } else {
        window.location.assign(process.env.REACT_APP_FULL_URL);
      }
    })
    .catch((error) => {
      if (isAuthenticated()) {
        api.get('/me', {}).then((meResponse) => {
          const account = meResponse.data;

          if (account.expired_account_confirmation === true) {
            logout();
          } else {
            window.location.assign(`${process.env.REACT_APP_FULL_URL}/login/?token=${getToken()}`);
          }
        });
      }
    });

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const Routes = () => {
  const getAccessToken = getUrlParam('access_token');

  useEffect(() => {
    removeCookie();
    if (getAccessToken === 'true') logout();
  });

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM,
        alignment: 'right',
        hide_default_launcher: true,
        horizontal_padding: 20,
        vertical_padding: 20,
        custom_launcher_selector: '#launcher',
      });
    }
  }, [window]);

  return (
    <>
      <Intercom appID={process.env.REACT_APP_INTERCOM} />
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <AuthRoute exact path="/">
              <Signin />
            </AuthRoute>
            <AuthRoute exact path="/accounts/sign_in">
              <Signin />
            </AuthRoute>
            <AuthRoute exact path="/accounts/verify">
              <Verify />
            </AuthRoute>
            <Route exact path="/start">
              <Signup />
            </Route>
            <SettingsRoute exact path="/accounts/settings">
              <AccountSettings />
            </SettingsRoute>
            <Route exact path="/accounts/forgot_password">
              <ForgotPassword />
            </Route>
            <Route exact path="/accounts/reset_password">
              <ResetPassword />
            </Route>
            <Route exact path="/accounts/forgot_password/mail_sent">
              <MailStatus status="sent" />
            </Route>
            <Route exact path="/accounts/forgot_password/mail_resent">
              <MailStatus status="resent" />
            </Route>
            <Route exact path="/accounts/confirmation/new">
              <ConfirmEmail />
            </Route>
            <Route exact path="/accounts/confirmed">
              <ConfirmedEmail />
            </Route>
            <AuthRoute path="*">
              <Signin />
            </AuthRoute>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default Routes;
